//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonIcon from '~/assets/svg/ic-plus-gradient.svg?inline';
import CloseIcon from '~/assets/svg/ic-close.svg?inline';

export default {
  components: { ButtonIcon, CloseIcon },

  props: {
    contentClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialog: false,
      title: '',
      text: '',
      button: false,
    };
  },

  methods: {
    show(section, country, button, prefix = 'rt0822') {
      this.debug({ name: 'show' });
      this.title = this.$t(`${prefix}.${section}.${country}.title`);
      this.text = this.$t(`${prefix}.${section}.${country}.text`);
      this.dialog = true;

      if (button) {
        this.button = this.$t(`${prefix}.${section}.${country}.button`);
      }
    },
  },
};
