//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PrivacyPolicy from '~/components/common/privacyPolicy';
import Phone from '~/components/common/phone';
import urlencodeFormData from '~/utils/urlencodeFormData';
import addAnyFormFields from '~/utils/addAnyFormFields';
import HintEmail from '../common/hintEmail.vue';
import ViewportMixin from '@/mixins/viewport.mixin';
import { getGRecaptchaScore } from '@/utils/grecaptcha';
import handleError from '@/utils/handleError';

export default {
  components: {
    Phone,
    PrivacyPolicy,
    ValidationObserver,
    ValidationProvider,
    HintEmail,
  },
  mixins: [ViewportMixin],
  props: {
    prefix: {
      type: String,
      default: 'rt0822',
    },
    disableReactivationLead: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: '',
      email: '',
      phone: '',
      rulesAccepted: true,
      loading: false,
    };
  },

  async mounted() {
    setTimeout(async () => {
      this.debug({ name: 'mounted' }, 'call addAnyFormFields');
      await addAnyFormFields.call(this, { ref: this.$refs.form });
    }, 1000);
  },

  methods: {
    async onSubmit() {
      this.loading = true;

      const formData = new FormData(this.$refs.form.$el.querySelector('form'));
      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('checkbox', this.rulesAccepted);

      const grecaptchaScore = await getGRecaptchaScore.bind(this)();
      formData.append('google_score', grecaptchaScore);

      this.debug(
        { name: 'onSubmit' },
        'data = %O',
        urlencodeFormData(formData),
      );
      try {
        await this.$axios({
          method: 'post',
          url: this.$app.config.current.feedbackUrl,
          data: urlencodeFormData(formData),
          headers: {
            'Content-Type': this.$app.config.current.feedbackContentType,
          },
        });

        // this.$refs.modal.show('form', 'success', true, this.prefix);

        this.$store.dispatch('modalNotify/OPEN', {
          title: this.$t(`${this.prefix}.form.success.title`),
          description: this.$t(`${this.prefix}.form.success.text`),
          action: this.$t(`${this.prefix}.form.success.button`),
        });

        this.$refs.form.reset();
        this.name = '';
        this.email = '';
        this.phone = '';

        this.$gtm.push({
          event: 'autoEvent',
          eventCategory: 'lead',
          eventAction: 'index_footer',
        });
      } catch (e) {
        handleError.bind(this)(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
