//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'scaled',
  head() {
    return {
      bodyAttrs: {
        class: 'index1',
      },
      title: this.$t('meta.index.title'),
      meta: [
        {
          hid: 'viewport',
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.index.description'),
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: `https://${this.$app.config.current.domain}${this.$route.path}`,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.$t('meta.index.title'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.$t('meta.index.description'),
        },
        {
          hid: 'og:type',
          name: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: `https://${
            this.$store?.state?.host
          }${require(`~/assets/${this.$app?.config?.common?.currentCountry}/ebac-logo.png`)}`,
        },
      ],
    };
  },
  computed: {
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
    isABTesting() {
      return this.$route.query?.ab === '1';
    },
  },
  methods: {
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
